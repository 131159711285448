

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import BackButton from '@/components/ui/BackButton.vue';
import Carousel from '@/components/ui/Carousel.vue';
import LoopTable from '@/components/ui/LoopTable.vue';
import SearchBar from '@/components/ui/SearchBar.vue';

@Component({
  components: {
    BackButton,
    Carousel,
    LoopTable,
    SearchBar,
  },
  computed: {
    ...mapState(['user']),
  },
})
export default class Library extends Vue {
  search = '';

  user: any;

  get isLibraryEmpty() {
    return !this.library.packs.length && !this.library.videos.length;
  }

  get library() {
    return this.user.profile.library;
  }

  mounted() {
    // go get library shit
    // this.$store.dispatch('getLibrary')
  }
}
